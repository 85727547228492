import { Helmet } from 'react-helmet';
import BizmixTopNavigation from '../../Components/NewBizmix/BizmixTopNavigation';

export default function BizmixLayout({ children }) {
  // const injectGA = () => {
  //   if (typeof window == 'undefined') {
  //     return;
  //   }
  //   window.dataLayer = window.dataLayer || [];
  //   function gtag() {
  //     window.dataLayer.push(arguments);
  //   }
  //   gtag('js', new Date());
  //   gtag('config', 'UA-121558694-1');
  // };

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-6H7WWPTT11"
        ></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-6H7WWPTT11');
        `}</script>
      </Helmet>
      {/* Global site tag (gtag.js) - Google Analytics */}
      {/* <div>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-121558694-1" />
        <script>{injectGA()}</script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-121558694-1"
        ></script>
      </div> */}

      <div
        className="bg-cover bg-fixed bg-no-repeat min-h-screen"
        style={{
          backgroundImage: 'url(/images/business-mixer/main-bg-old.png)'
        }}
      >
        <section className="h-full min-h-screen w-full">
          <BizmixTopNavigation />
          <main className="">{children}</main>
        </section>
      </div>
    </>
  );
}
